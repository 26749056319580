import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PaidRounded from '@mui/icons-material/PaidRounded';
import ThumbUpAltRounded from '@mui/icons-material/ThumbUpAltRounded';
import { Link } from 'react-router-dom';


export default function MenuContent() {
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
      <ListItem button component={Link} to="/">
            <ListItemButton>
                <HomeRoundedIcon />
                <ListItemText primary="Users" />
            </ListItemButton>
        </ListItem>
        <ListItem button component={Link} to="/usage">
            <ListItemButton>
                <PeopleRoundedIcon />
                <ListItemText primary="Usage" />
            </ListItemButton>
        </ListItem>
        <ListItem button component={Link} to="/performance">
            <ListItemButton>
                <PaidRounded />
                <ListItemText primary="Performance" />
            </ListItemButton>
        </ListItem>
        <ListItem button component={Link} to="/monitoring">
            <ListItemButton>
                <PaidRounded />
                <ListItemText primary="Monitoring" />
            </ListItemButton>
        </ListItem>
        <ListItem button component={Link} to="/feedback">
            <ListItemButton>
                <ThumbUpAltRounded />
                <ListItemText primary="Feedback" />
            </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );
}

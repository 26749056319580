import React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StatCard from './components/MonitoringMetricCards';
import Copyright from './internals/components/Copyright';
import ChartUserByCountry from './components/TemplateUsage';  // Import the component
import SessionsChart from './components/ExecutionTimeVsAudioLengthLineChart';
import useAuth from '../../useAuth';

export default function MonitoringPage({ data }) {
  useAuth();
  const monitoringData = [
    {
      title: 'Consultations today',
      value: data?.consultations_today ?? '-',
    },
    {
      title: 'Consultations failed',
      value: data?.consultations_failed ?? '-',
    },
    {
      title: 'Consultations errored',
      value: data?.consultations_errored ?? '-',
    },
    {
      title: 'Success rate',
      value: data?.success_rate ? `${data.success_rate * 100}%` : '-',
    },
    {
      title: 'Total audio length',
      value: `${data?.total_audio_length ?? '-'} minutes`,
    },
    {
      title: 'Mean audio length',
      value: `${data?.mean_audio_length ?? '-'} minutes`,
    },
    {
      title: 'Mean transcription time',
      value: `${data?.mean_transcribe_time ?? '-'} seconds`,
    },
    {
      title: 'Mean generation time',
      value: `${data?.mean_generation_time ?? '-'} seconds`,
    },
    {
      title: 'Regenerations',
      value: data?.consultations_regen_count ?? '-',
    },
    {
      title: 'Average regenerations per consultation',
      value: data?.avg_regens ?? '-',
    },
    {
      title: 'Execution time per minute of audio',
      value: `${data?.execution_time_per_min_audio ?? '-'} seconds`,
    },
    {
      title: 'Input token',
      value: data?.input_tokens_total ?? '-',
    },
    {
      title: 'Output tokens',
      value: data?.output_tokens_total ?? '-',
    },
    {
      title: 'Number of times template 1 was used',
      value: data?.total_template_one ?? '-',
    },
    {
      title: 'Number of times template 2 was used',
      value: data?.total_template_two ?? '-',
    },
    {
      title: 'Number of times template 3 was used',
      value: data?.total_template_three ?? '-',
    },
    {
      title: 'Number of times template 4 was used',
      value: data?.total_template_four ?? '-',
    },
    {
      title: 'Total OpenAI requests',
      value: data?.openai_total_requests ?? '-',
    },
    {
      title: 'Total OpenAI minutes',
      value: data?.openai_total_mins ?? '-',
    },
    {
      title: 'Mean generation time %',
      value: `${data?.mean_time_to_generate_percentage ?? '-'}%`,
    },
    {
      title: 'Mean transcription time %',
      value: `${data?.mean_time_to_transcribe_percentage ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (northcentralus)',
      value: `${data?.mean_time_to_transcribe_percentage_northcentralus ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (norwayeast)',
      value: `${data?.mean_time_to_transcribe_percentage_norwayeast ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (southindia)',
      value: `${data?.mean_time_to_transcribe_percentage_southindia ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (swedencentral)',
      value: `${data?.mean_time_to_transcribe_percentage_swedencentral ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (westeurope)',
      value: `${data?.mean_time_to_transcribe_percentage_westeurope ?? '-'}%`,
    },
    {
      title: 'Mean transcription time % (OpenAI)',
      value: `${data?.mean_time_to_transcribe_percentage_openai ?? '-'}%`,
    },
    {
      title: 'Mean generation time % (southcentralus)',
      value: `${data?.mean_time_to_generate_percentage_southcentralus ?? '-'}%`,
    },
    {
      title: 'Mean generation time % (swedencentral)',
      value: `${data?.mean_time_to_generate_percentage_swedencentral ?? '-'}%`,
    },
    {
      title: 'Mean generation time % (eastus)',
      value: `${data?.mean_time_to_generate_percentage_eastus ?? '-'}%`,
    },
    {
      title: 'Mean generation time % (westus)',
      value: `${data?.mean_time_to_generate_percentage_westus ?? '-'}%`,
    },
    {
      title: 'Mean generation time % (westus3)',
      value: `${data?.mean_time_to_generate_percentage_westus3 ?? '-'}%`,
    },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
        Monitoring Overview
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
        {monitoringData.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard title={card.title} value={card.value} />
          </Grid>
        ))}
      </Grid>

      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        User Table
      </Typography>

      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, md: 12, lg: 9 }}>
          <SessionsChart />
        </Grid>

        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            {/* Pass data props to ChartUserByCountry */}
            <ChartUserByCountry
              templateOne={data?.total_template_one ?? 0}
              templateTwo={data?.total_template_two ?? 0}
              templateThree={data?.total_template_three ?? 0}
              templateFour={data?.total_template_four ?? 0}
              consultationsToday={data?.consultations_today ?? 0}
            />
          </Stack>
        </Grid>
      </Grid>

      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}

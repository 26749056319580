import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

function renderSparklineCell(params) {
  const data = getDaysInMonth(4, 2024);
  const { value, colDef } = params;

  if (!value || value.length === 0) {
    return null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <SparkLineChart
        data={value}
        width={colDef.computedWidth || 100}
        height={32}
        plotType="bar"
        showHighlight
        showTooltip
        colors={['hsl(210, 98%, 42%)']}
        xAxis={{
          scaleType: 'band',
          data,
        }}
      />
    </div>
  );
}

function renderStatus(status) {
  const colors = {
    Online: 'success',
    Offline: 'default',
  };

  return <Chip label={status} color={colors[status]} size="small" />;
}

export function renderAvatar(params) {
  if (params.value == null) {
    return '';
  }

  return (
    <Avatar
      sx={{
        backgroundColor: params.value.color,
        width: '24px',
        height: '24px',
        fontSize: '0.85rem',
      }}
    >
      {params.value.name.toUpperCase().substring(0, 1)}
    </Avatar>
  );
}

export const columns = [
  { 
    field: 'DoctorID', 
    headerName: 'Doctor ID', 
    flex: 1.5, 
    minWidth: 80,
  },
  {
    field: 'subscriptionLevel',
    headerName: 'Subscription',
    flex: 0.5,
    minWidth: 80,
    renderCell: (params) => renderStatus(params.value),
  },
  {
    field: 'Vendor',
    headerName: 'Vendor',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'FreeTrial_start_date',
    headerName: 'Free Trial Start',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'FreeTrial_end_date',
    headerName: 'Free Trial End',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'usage_counter',
    headerName: 'Total Usage',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'daily_usage',
    headerName: 'Daily Usage',
    flex: 1,
    minWidth: 150,
    renderCell: renderSparklineCell,
  },
];

export const rows = [
  {
    id: 1,
    DoctorID: 893012,
    subscriptionLevel: 'Online',
    Vendor: 8345,
    FreeTrial_start_date: 212423,
    FreeTrial_end_date: 18.5,
    usage_counter: '2m 15s',
    daily_usage: [
      469172, 488506, 592287, 617401, 640374, 632751, 668638, 807246, 749198, 944863,
      911787, 844815, 992022, 1143838, 1446926, 1267886, 1362511, 1348746, 1560533,
      1670690, 1695142, 1916613, 1823306, 1683646, 2025965, 2529989, 3263473,
      3296541, 3041524, 2599497,
    ],
},
{
  id: 2,
  DoctorID: 893012,
  subscriptionLevel: 'Online',
  Vendor: 8345,
  FreeTrial_start_date: 212423,
  FreeTrial_end_date: 18.5,
  usage_counter: '2m 15s',
  daily_usage: [
    469172, 488506, 592287, 617401, 640374, 632751, 668638, 807246, 749198, 944863,
    911787, 844815, 992022, 1143838, 1446926, 1267886, 1362511, 1348746, 1560533,
    1670690, 1695142, 1916613, 1823306, 1683646, 2025965, 2529989, 3263473,
    3296541, 3041524, 2599497,
  ],
},
{
  id: 3,
  DoctorID: 893012,
  subscriptionLevel: 'Online',
  Vendor: 8345,
  FreeTrial_start_date: 212423,
  FreeTrial_end_date: 18.5,
  usage_counter: '2m 15s',
  daily_usage: [
    469172, 488506, 592287, 617401, 640374, 632751, 668638, 807246, 749198, 944863,
    911787, 844815, 992022, 1143838, 1446926, 1267886, 1362511, 1348746, 1560533,
    1670690, 1695142, 1916613, 1823306, 1683646, 2025965, 2529989, 3263473,
    3296541, 3041524, 2599497,
  ],
},
];

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function getDaysInCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth(); // 0-based index, January is 0
  const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the last day of the month
  const days = [];

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(`${now.toLocaleString('en-US', { month: 'short' })} ${i}`);
  }

  return days;
}

export default function TotalUsersLineChart({ totalUsers }) {
  console.log("Total Users in Chart:", totalUsers); // Check the received value
  const theme = useTheme();
  const data = getDaysInCurrentMonth(); // Get the current month's days

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Total users by month
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {totalUsers !== null ? totalUsers : 'Loading...'}
            </Typography>
            <Chip size="small" color="success" label="+35%" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Total users per month for the last 6 months
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data: data,  // Dynamically generated data for the x-axis
              tickInterval: (index, i) => (i + 1) % 5 === 0,
            },
          ]}
          series={[
            {
              id: 'direct',
              label: 'Total Users',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                3, 5, 4, 7, 6, 9, 12, 10, 13, 14, 11, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 22, 24, 25, 26
              ],
            },
            {
              id: 'referral',
              label: 'Total Paid Users',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                0, 2, 1, 4, 3, 6, 9, 7, 10, 11, 8, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 19, 21, 22, 23
              ],
            },
            {
              id: 'organic',
              label: 'Total Free Users',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              stackOrder: 'ascending',
              data: [
                1, 3, 2, 5, 4, 7, 10, 8, 11, 12, 9, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 20, 22, 23, 24
              ],
              area: true,
            },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-organic': {
              fill: "url('#organic')",
            },
            '& .MuiAreaElement-series-referral': {
              fill: "url('#referral')",
            },
            '& .MuiAreaElement-series-direct': {
              fill: "url('#direct')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={theme.palette.primary.dark} id="organic" />
          <AreaGradient color={theme.palette.primary.main} id="referral" />
          <AreaGradient color={theme.palette.primary.light} id="direct" />
        </LineChart>
      </CardContent>
    </Card>
  );
}

TotalUsersLineChart.propTypes = {
  totalUsers: PropTypes.number,
};

import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { columns } from '../internals/data/gridData';

export default function CustomizedDataGrid() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch the data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://yoc4h9xzq3.execute-api.af-south-1.amazonaws.com/dev/user-table', {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY, // Replace with your API key variable
          }
        });
        
        const apiData = response.data;

        // Map API data to the structure required by the DataGrid
        const formattedRows = apiData.map((item, index) => ({
          id: index + 1,
          DoctorID: item.DoctorID,
          subscriptionLevel: item.subscriptionLevel,
          Vendor: item.Vendor,
          FreeTrial_start_date: item.FreeTrial_start_date || 'N/A', // Handle missing data
          FreeTrial_end_date: item.FreeTrial_end_date || 'N/A', // Handle missing data
          usage_counter: item.usage_counter || 0, // Handle missing data
          daily_usage: item.daily_usage ? [item.daily_usage] : [], // Ensure it's an array for the SparklineChart
        }));

        setRows(formattedRows);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      rows={rows}
      columns={columns}
      loading={loading}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      pageSizeOptions={[10, 20, 50]}
      disableColumnResize
      density="compact"
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
          },
        },
      }}
    />
  );
}

import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Logo from '../images/nora-logo-blue.png';

const logoStyle = {
  width: '180px',
  height: 'auto',
  cursor: 'pointer',
};

function SelectContent() {
  return (
    <Card
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={Logo}
                style={logoStyle}
                alt="logo of nora"
              />
     </Card>         
  );
}

export default SelectContent;

import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from './internals/components/Copyright';
import CustomizedDataGrid from './components/UsageTable';
import PageViewsBarChart from './components/MonthlyConsultsBarChart';
import SessionsChart from './components/DailyConsultsLineChart';
import StatCard from './components/UsageMetricsCards';
import useAuth from '../../useAuth';

export default function MainGrid({ data, loading }) {
  useAuth();
  if (loading) {
    return (
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' }, textAlign: 'center' }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!data) return null; // Or some error message if needed

  const cardsData = [
    {
      title: 'Total Consultations',
      value: data.consultations_total?.toString() || 'N/A',
      interval: 'Last 30 days',
      trend: 'up', // This can be determined based on the data
      data: [
        200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
        360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
      ],
    },
    {
      title: 'Monthly Consultations',
      value: data.consultations_this_month?.toString() || 'N/A',
      interval: 'Last 30 days',
      trend: 'down', // This can be determined based on the data
      data: [
        1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
        780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
      ],
    },
    {
      title: 'Last 7 days Consultations',
      value: data.consultations_last_seven?.toString() || 'N/A',
      interval: 'Last 7 days',
      trend: 'neutral', // This can be determined based on the data
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Today Consultations',
      value: data.consultations_today?.toString() || 'N/A',
      interval: 'Today',
      trend: 'up', // This can be determined based on the data
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: {xs: 6, md: 2}}}>
        Usage Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {cardsData.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
        <SessionsChart todayConsultations={data.consultations_today?.toString() || 'N/A'} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
        <PageViewsBarChart monthlyConsultations={data.consultations_this_month?.toString() || 'N/A'} />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Usage Table
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, md: 12, lg: 12 }}>
          <CustomizedDataGrid />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
          </Stack>
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}

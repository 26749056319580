import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Card as MuiCard } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import getSignInTheme from './getSignInTheme';
import ToggleColorMode from './ToggleColorMode';
import { verifyTotpChallenge } from '../../authUtils';  // Add TOTP verification logic

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  gap: theme.spacing(4),
  width: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    width: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'auto',
  paddingBottom: theme.spacing(12),
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,
    height: '100dvh',
  },
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.3), hsl(220, 30%, 5%))',
  }),
}));

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);  // New state for TOTP handling
  const [mfaCode, setMfaCode] = useState('');  // New state for TOTP code
  const [user, setUser] = useState(null);  // Store CognitoUser object for later MFA verification
  const navigate = useNavigate();

  const [mode, setMode] = useState('light');
  const [showCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignInTheme = createTheme(getSignInTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const cognitoUsername = result.getIdToken().payload.sub;

        sessionStorage.setItem('idToken', idToken);
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('cognitoUsername', cognitoUsername);

        setLoading(false);
        navigate('/');
      },
      onFailure: (err) => {
        setError(err.message || "Login failed. Please try again.");
        setLoading(false);
      },
      mfaRequired: (challengeName, session) => {
        setMfaRequired(true);  // Enable MFA input
        setUser(cognitoUser);  // Store CognitoUser for later use in MFA
        setLoading(false);
      },
      newPasswordRequired: () => {
        setError("New password required. Please reset your password.");
        setLoading(false);
      },
    });
  };

  // Function to handle TOTP code submission
  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await verifyTotpChallenge(user, mfaCode);  // Use TOTP verification logic from authUtils
      setLoading(false);
      navigate('/');
    } catch (err) {
      setError("Invalid TOTP code. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleFocusIn = () => {
      document.body.classList.add('keyboard-open');
    };

    const handleFocusOut = () => {
      document.body.classList.remove('keyboard-open');
    };

    window.addEventListener('focusin', handleFocusIn);
    window.addEventListener('focusout', handleFocusOut);

    return () => {
      window.removeEventListener('focusin', handleFocusIn);
      window.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  return (
    <ThemeProvider theme={showCustomTheme ? SignInTheme : defaultTheme}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            position: { xs: 'static', sm: 'fixed' },
            width: '100%',
            p: { xs: 2, sm: 4 },
          }}
        >
          <Button
            startIcon={<ArrowBackRoundedIcon />}
            onClick={() => navigate('/')}
          >
            Back
          </Button>
          <ToggleColorMode
            data-screenshot="toggle-mode"
            mode={mode}
            toggleColorMode={toggleColorMode}
          />
        </Stack>
        <Stack
          sx={{
            justifyContent: 'center',
            height: { xs: '100%', sm: '100dvh' },
            p: 2,
          }}
        >
          <Card>
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={mfaRequired ? handleMfaSubmit : handleLogin}  // Conditional form submission
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  error={Boolean(error)}
                  id="email"
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  autoComplete="email"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading || mfaRequired}  // Disable if MFA is required
                />
              </FormControl>
              {!mfaRequired && (
                <FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                  </Box>
                  <TextField
                    error={Boolean(error)}
                    name="password"
                    placeholder="••••••"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    color="primary"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={loading}
                  />
                </FormControl>
              )}
              {mfaRequired && (
                <FormControl>
                  <FormLabel htmlFor="mfaCode">TOTP Code</FormLabel>  {/* New input for TOTP */}
                  <TextField
                    error={Boolean(error)}
                    id="mfaCode"
                    type="text"
                    name="mfaCode"
                    placeholder="Enter your TOTP code"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    color="primary"
                    value={mfaCode}
                    onChange={(e) => setMfaCode(e.target.value)}
                    disabled={loading}
                  />
                </FormControl>
              )}
              {error && <Typography color="error">{error}</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
              >
                {loading ? 'Signing in...' : mfaRequired ? 'Submit TOTP Code' : 'Sign in'}  {/* Updated button text */}
              </Button>
            </Box>
          </Card>
        </Stack>
      </SignInContainer>
    </ThemeProvider>
  );
}

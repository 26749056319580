// DashboardLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import SideMenu from './components/SideMenu';
import Navbar from './components/Navbar';
import Header from './components/Header';
import ToggleCustomTheme from './components/theme/ToggleCustomTheme';

export default function DashboardLayout({ mode, toggleColorMode, dashboardData, loading, showCustomTheme, toggleCustomTheme }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Navbar mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        component="main"
        sx={(theme) => ({
          position: { sm: 'relative', md: '' },
          top: { sm: '48px', md: '0' },
          height: { sm: 'calc(100vh - 48px)', md: '100vh' },
          flexGrow: 1,
          pt: 2,
          backgroundColor: theme.palette.background.default,
          overflow: 'auto',
        })}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 10,
          }}
        >
          <Header mode={mode} toggleColorMode={toggleColorMode} />
          <Outlet /> {/* This renders the child route components */}
        </Stack>
      </Box>
      <ToggleCustomTheme showCustomTheme={showCustomTheme} toggleCustomTheme={toggleCustomTheme} />
    </Box>
  );
}

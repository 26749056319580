import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from './authUtils';

const useAuth = (redirectUrl = '/login') => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) { // If no valid token, redirect to login page
      navigate(redirectUrl);
    }
  }, [navigate, redirectUrl]);
};

export default useAuth;

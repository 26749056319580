// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import getDashboardTheme from './components/theme/getDashboardTheme';
import Users from './pages/users/UsersPage';
import Usage from './pages/usage/UsagePage';
import Feedback from './pages/feedback/FeedbackPage';
import Performance from './pages/performance/PerformancePage';
import Monitoring from './pages/monitoring/MonitoringPage';
import Login from './pages/login/login';
import DashboardLayout from './AdminPanelLayout';

export default function App() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    axios.get('https://yoc4h9xzq3.execute-api.af-south-1.amazonaws.com/dev/main-table', {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      }
    })
    .then((response) => {
      const roundedData = roundDashboardData(response.data);
      setDashboardData(roundedData);
      setLoading(false);
    })
    .catch((error) => {
      console.error("There was an error fetching the dashboard data!", error);
      setLoading(false);
    });
  }, []);

  const roundDashboardData = (data) => {
    const roundValue = (value) => {
      return typeof value === 'number' ? Math.round(value * 100) / 100 : value;
    };

    const roundedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        roundedData[key] = roundValue(data[key]);
      }
    }
    return roundedData;
  };

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <Router>
      <ThemeProvider theme={showCustomTheme ? dashboardTheme : defaultTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          
          {/* Dashboard routes with shared layout */}
          <Route element={
            <DashboardLayout
              mode={mode}
              toggleColorMode={toggleColorMode}
              dashboardData={dashboardData}
              loading={loading}
              showCustomTheme={showCustomTheme}
              toggleCustomTheme={toggleCustomTheme}
            />
          }>
            <Route path="/" element={<Users totalUsers={dashboardData?.total_users} />} />
            <Route path="/usage" element={<Usage data={dashboardData} loading={loading} />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/performance" element={<Performance data={dashboardData} loading={loading} />} />
            <Route path="/monitoring" element={<Monitoring data={dashboardData} loading={loading} />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </Router>
  );
}
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from './internals/components/Copyright';
import ChartUserByCountry from './components/ChartUserByTemplate';
import CustomizedDataGrid from './components/UserTable';
import PageViewsBarChart from './components/NewUsersBarChart';
import SessionsChart from './components/TotalUsersLineChart';
import StatCard from './components/UserMetricCards';
import useAuth from '../../useAuth';

export default function UsersPage() {
  useAuth(); // This will redirect if not authenticated
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // Fetch API data with API key and accessToken
    const fetchData = async () => {
      const accessToken = sessionStorage.getItem('accessToken'); // Retrieve the access token

      if (!accessToken) {
        console.error('No access token found. Please login again.');
        return;
      }

      try {
        const response = await fetch(
          'https://yoc4h9xzq3.execute-api.af-south-1.amazonaws.com/dev/user-snapshot',
          {
            headers: {
              'x-api-key': `${process.env.REACT_APP_API_KEY}`, // API key
              Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header
            },
          }
        );
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();
  }, []);

  const data = [
    {
      title: 'Total Users',
      value: apiData ? apiData.total_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
        360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
      ],
    },
    {
      title: 'Premium Users',
      value: apiData ? apiData.total_premium_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
        780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
      ],
    },
    {
      title: 'Free Trial Users',
      value: apiData ? apiData.total_free_trial_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'neutral', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Monthly Active Users',
      value: apiData ? apiData.monthly_active_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Weekly Active Users',
      value: apiData ? apiData.weekly_active_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Daily Active Users',
      value: apiData ? apiData.daily_active_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'New Monthly Users',
      value: '-',
      interval: 'Last 30 days',
      trend: 'neutral',
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'New Monthly Paid Users',
      value: '-',
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* Cards and charts */}
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
        Users Overview
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <SessionsChart totalUsers={apiData ? apiData.total_users : 'Loading...'} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        User Table
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ XS: 12, md: 12, lg: 9 }}>
          <CustomizedDataGrid />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            <ChartUserByCountry />
          </Stack>
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
